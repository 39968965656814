<template>
	<vue-html2pdf
		:show-layout="false"
		:float-layout="true"
		:enable-download="true"
		:preview-modal="false"
		:manual-pagination="true"
		@progress="onProgress($event)"
		@beforeDownload='beforeDownload'
		ref="html2Pdf"
		:html-to-pdf-options="htmlToPdfOptions"
	>
		<section slot="pdf-content" class='pdf-content border'>
			<section>
				<div class='a-pdf-header'>
					<div class='row align-items-center'>
						<div class='col'>
							<img class='a-pdf-logo' :src="$store.state.auth.user.logo" v-if='$store.state.auth.user.logo'>
							<span class='a-pdf-logo' v-html="require('!html-loader!@/assets/logo-color.svg')" v-else />
						</div>
					</div>
				</div>
			</section>

			<slot name='content' />

			<section>
				<div class='a-pdf-footer'>
					<div class='row align-items-center'>
						<div class='col'>
							<p class='a-pdf-footer-title' v-html="$store.state.auth.user.companyName"></p>
						</div>
						<div class='col-auto'>
							<a class='a-pdf-footer-phone' :href="'tel:+' + $store.state.auth.user.phone">+{{$store.state.auth.user.phone}}</a>
							<a class='a-pdf-footer-email' :href="'mailto:' + $store.state.auth.user.email">{{ $store.state.auth.user.email }}</a>
						</div>
					</div>
				</div>
			</section>
		</section>
	</vue-html2pdf>
</template>

<script>
	import VueHtml2pdf from 'vue-html2pdf';

	export default {
		props: ["filename"],
		components: {
			VueHtml2pdf
		},
		computed: {
			htmlToPdfOptions() {
				return {
					margin: 0,
					filename: this.filename,
					image: {
						type: 'jpeg',
						quality: 0.95
					},
					enableLinks: true,
					html2canvas: {
						scale: 5,
						useCORS: true,
						// allowTaint: true,
						// proxy: 'http://localhost:8080',
						scrollX: 0,
						scrollY: 0
					},
					jsPDF: {
						orientation: 'p',
						unit: 'mm',
						format: 'a4',
						putOnlyUsedFonts: true,
						floatPrecision: 16
					},
					pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
				};
			}
		},
		methods: {
			beforeDownload() {
				this.$emit("update-loading", true);
			},
			onProgress($event) {
				if($event == 100) {
					this.$emit("update-loading", false);
				}
			}
		}
	}
</script>
